import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Article from '../components/article'
import ContactForm from '../components/contactForm'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faLocationArrow,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'

const ContactUs = () => {
  return (
    <>
      <Seo
        pageTitle={'Contact Us'}
        description={`Send us a contact request or get in touch with our contact details.`}
      />
      <Layout>
        <main>
          <Article>
            <div className='card shadow border-primary mb-3 p-2'>
              <StaticImage
                className='card-img-top'
                src={'../images/emergency.png'}
                alt={'Combined Home Insurance'}
                placeholder='blurred'
                Layout={'fullWidth'}
                formats={['auto', 'webp', 'avif']}
                quality={100}
              ></StaticImage>
            </div>
            <h1 className='display-4'>Contact Us</h1>

            <div className='row justify-content-between'>
              <div className='col-lg-9'>
                <ContactForm />
              </div>

              <div className='col-lg-3 d-none d-sm-none d-md-none d-lg-block'>
                <div className='row '>
                  <div className='col-auto text-end text-primary'>
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <div className='col-auto'>
                    <a href='tel:03454503359'>0345 450 3359</a>
                  </div>
                </div>
                <div className='row '>
                  <div className='col-auto text-end text-primary'>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <div className='col-auto'>
                    <a href='mailto:info@legacyhp.co.uk'>info@legacyhp.co.uk</a>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-auto text-end text-primary'>
                    <FontAwesomeIcon icon={faLocationArrow} />
                  </div>
                  <div className='col-auto'>
                    Provincial House<br></br>
                    37 New Walk<br></br>
                    Leicester<br></br>
                    LE1 6TE
                  </div>
                </div>
              </div>
            </div>
          </Article>
        </main>
      </Layout>
    </>
  )
}

export default ContactUs

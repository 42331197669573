import React, { useState } from 'react'

const ContactForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [renewalDate, setRenewalDate] = useState('')
  const [currentProvider, setCurrentProvider] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState('')

  const handleSubmit = event => {
    event.preventDefault()
    postFormData()
  }

  const handleNameChange = event => {
    setName(event.target.value)
  }

  const handleEmailChange = event => {
    setEmail(event.target.value)
  }

  const handleTelChange = event => {
    setTel(event.target.value)
  }

  const handleRenewalDateChange = event => {
    setRenewalDate(event.target.value)
  }

  const handleCurrentProviderChange = event => {
    setCurrentProvider(event.target.value)
  }

  const handleMessageChange = event => {
    setMessage(event.target.value)
  }

  const postFormData = async () => {
    setIsLoading(true)

    const data = {
      name: name,
      email: email,
      tel: tel,
      renewalDate: renewalDate,
      currentProvider: currentProvider,
      message: message
    }

    const url = ' https://legacy-sendgrid-worker.legacywills.workers.dev'

    const reponse = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      redirect: 'follow',
      referrer: 'client',

      body: JSON.stringify(data)
    })

    if (reponse.ok) {
      setIsLoading(false)
      alert(
        `Thank you for your submission, ${
          name.split(' ')[0]
        }. We'll be in touch as soon as possible`
      )
    } else {
      setIsLoading(false)
      alert(`System Error`)
    }
  }
  return (
    <div className='row'>
      <div className='col'>
        <div className='card shadow border-primary'>
          <div className='card-body'>
            <form onSubmit={handleSubmit}>
              <div className='mb-3'>
                <label htmlFor='name' className='form-label'>
                  Name
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='name'
                  aria-describedby='nameHelp'
                  placeholder='John Doe'
                  onChange={handleNameChange}
                  value={name.value}
                  required
                />
                <div id='nameHelp' className='form-text'></div>
              </div>

              <div className='mb-3'>
                <label htmlFor='email' className='form-label'>
                  Email
                </label>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  aria-describedby='emailHelp'
                  placeholder='john.doe@example.com'
                  onChange={handleEmailChange}
                  value={email}
                  required
                />
                <div id='emailHelp' className='form-text'></div>
              </div>

              <div className='mb-3'>
                <label htmlFor='phone' className='form-label'>
                  Telephone
                </label>
                <input
                  type='tel'
                  className='form-control'
                  id='phone'
                  aria-describedby='phoneHelp'
                  placeholder='07123 456 789'
                  onChange={handleTelChange}
                  value={tel}
                  required
                />
                <div id='phoneHelp' className='form-text'></div>
              </div>

              <div className='mb-3'>
                <label htmlFor='renewalDate' className='form-label'>
                  Insurance Renewal Date
                </label>
                <input
                  type='date'
                  className='form-control'
                  id='renewalDate'
                  aria-describedby='renewalDateHelp'
                  placeholder='07123 456 789'
                  onChange={handleRenewalDateChange}
                  value={renewalDate}
                />
                <div id='renewalDateHelp' className='form-text'></div>
              </div>

              <div className='mb-3'>
                <label htmlFor='currentProvider' className='form-label'>
                  Current Insurance Provider
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='currentProvider'
                  aria-describedby='currentProviderHelp'
                  onChange={handleCurrentProviderChange}
                  value={currentProvider}
                />
                <div id='currentProviderHelp' className='form-text'></div>
              </div>

              <div className='mb-3'>
                <label htmlFor='message' className='form-label'>
                  Message{' '}
                </label>
                <textarea
                  rows='5'
                  className='form-control'
                  id='message'
                  aria-describedby='messageHelp'
                  onChange={handleMessageChange}
                  value={message}
                />
                <div id='messageHelp' className='form-text'></div>
              </div>
              <div>
                <button
                  className='btn-lg btn-primary'
                  type='submit'
                  disabled={isLoading}
                >
                  <span>
                    {!isLoading && 'Submit'}
                    {isLoading && 'Loading...'}
                  </span>
                </button>
                <span className='mx-3'>
                  {isLoading && (
                    <div
                      className='spinner-border spinner-border-sm align-middle'
                      role='status'
                      style={{ width: '2rem', height: '2rem' }}
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  )}
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
